/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import { useValidatedField, ValidatedFieldType } from '@/hooks/useValidatedField'

import { useRouter } from 'next/router'
import { error } from '@/services/Log'
import { phoneValidator } from '@csc/dls/utils'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import dynamic from 'next/dynamic'
import { GAEmailSubscription } from '@/types/ThirdPartyIntegrations/GAEvents'

const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))
const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))

const Heading2 = dynamic(import('@csc/dls/Heading2'))

const PrimaryButton = dynamic(import('@csc/dls/PrimaryButton'))
const Checkbox = dynamic(import('@csc/dls/Checkbox'))

const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))

type FieldReactor<E = React.ChangeEvent<HTMLInputElement>, K = string> = (
  (field: ValidatedFieldType<K>) => (e: E) => void
)

const SignUpForm: React.FC<{
  headingMessage?: string
  reloadCustomer?: () => void
}> = ({
  headingMessage = 'Create an Account',
  reloadCustomer,
}) => {
  const router = useRouter()
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [submitFailed, setSubmitFailed] = useState<boolean>(false)

  const required = (value: string): string => {
    if (!submitAttempted) {
      return ''
    }
    return `${value || ''}`.trim() ? '' : 'Required'
  }

  // eslint-disable-next-line no-control-regex
  const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
  const validEmail = (email: string) => {
    if (!submitAttempted) {
      return ''
    }
    if (!emailPattern.test(email)) {
      return 'Please enter a valid Email'
    }
    return ''
  }
  const showFeedBack = () => submitAttempted

  const firstName = useValidatedField('', required, showFeedBack)
  const lastName = useValidatedField('', required, showFeedBack)
  const email = useValidatedField('', validEmail, showFeedBack)

  const passWordCheck = (password: string) => {
    const passwordLengthFailed = password.length < 7
    if (passwordLengthFailed) {
      return 'Passwords must have at least 7 characters'
    }

    const passwordAlphabeticFailed = !(/([a-zA-Z]+)/i.test(password))
    const passwordNumericFailed = !(/([0-9]+)/.test(password))

    if (passwordAlphabeticFailed) {
      return 'Passwords must contain at least one alphabetic character'
    }
    if (passwordNumericFailed) {
      return 'Passwords must contain at least one numeric character'
    }
    return ''
  }
  const password1 = useValidatedField('', passWordCheck, showFeedBack)

  type PasswordCheckerType = (field: ValidatedFieldType) => (value: string) => string
  const passWordRedundancyCheck: PasswordCheckerType = ({ value: password1Value }) => (
    (value: string) => {
      if (value !== password1Value) {
        return 'Both Passwords must match'
      }
      return ''
    }
  )

  const password2 = useValidatedField('', passWordRedundancyCheck(password1), showFeedBack)

  const validatePhone: ((string: string) => string) = (phone: string) => {
    if (!phone) {
      return 'Required'
    }
    const validatedValue = phoneValidator.validate.validPhoneNumber(phone)
    if (validatedValue && typeof validatedValue === 'string') {
      return validatedValue
    }
    return ''
  }
  const phone = useValidatedField('', validatePhone, showFeedBack)
  const emailSignUp = useValidatedField<boolean>(false)

  const formFields = [
    firstName,
    lastName,
    email,
    password1,
    password2,
    phone,
    emailSignUp,
  ]

  const withFormChange: FieldReactor<React.ChangeEvent<HTMLInputElement>> = (
    ({ setValue }) => (e) => setValue(e.target.value)
  )
  const trimOnBlur: FieldReactor<React.FocusEvent<HTMLInputElement>> = ({ setValue, value }) => () => setValue(`${value}`.trim())

  const trackSignUp = (userEmail : string) => {
    const dataLayerEvent: GAEmailSubscription[keyof GAEmailSubscription] = {
      event: 'Email Sign Ups - Create Account',
      customerEmail: userEmail,
    }
    try {
      window?.dataLayer?.push(dataLayerEvent)
    } catch (e) {
      console.error(e)
    }
  }

  const trackFacebookEventRegistration = (userEmail : string) => {
    try {
      allPromisesWithRetries(() => [import('@/services/Facebook')])
        .then(([{ Facebook }]) => Facebook.completeRegistration({
          content_name: 'sign-up',
          user_email: userEmail,
          status: true,
        })).catch((err) => {
          error('Failed to submit registration Event to Facebook Pixel', err)
        })
    } catch (err) {
      error('Failed to submit registration Event to Facebook Pixel', err)
    }
  }

  const trackListrakSubscriptions = (userEmail : string) => {
    try {
      window?._ltk?.SCA?.Update('email', userEmail)
    } catch (err) {
      error('Failed to submit registration Event to Facebook Pixel', err)
    }
  }

  const onFormSubmit = async () => {
    if (isSubmitting) {
      return
    }
    try {
      const hasIssues = formFields
        ?.map(({ issues }) => issues)
        ?.filter((issues) => !!issues)?.length > 0
      const hasRequiredData = (
        !!firstName.value
        && !!lastName.value
        && !!email.value
        && !!password1.value
        && !!password2.value
        && password1.value === password2.value
        && !!phone.value
      )

      if (hasIssues || !hasRequiredData) {
        setSubmitAttempted(true)
        formFields.find(({ issues }) => issues)?.ref?.current?.focus()
      } else {
        setIsSubmitting(true)
        setSubmitAttempted(false)
        setSubmitFailed(false)
        const newCustomer = {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          password: password1.value,
          phone: phone.value,
          acceptsMarketingEmails: !!emailSignUp.value,
        }
        try {
          const [{ default: Account }] = await allPromisesWithRetries(() => [import('@/services/Account')])
          await Account.createCustomer(newCustomer)
          const { success } = await Account.signUserIn({
            email: newCustomer.email,
            password: newCustomer.password,
          })
          if (success) {
            if (emailSignUp.value) {
              trackSignUp(email.value)
              trackFacebookEventRegistration(email.value)
              trackListrakSubscriptions(email.value)
            }
            if (reloadCustomer) {
              reloadCustomer()
            } else {
              await router.push('/account')
            }
          }
        } catch (err) {
          setSubmitFailed(true)
        }
      }
    } catch (err) {
      error('Critical Failure in account creation', err)
    } finally {
      setIsSubmitting(false)
    }
  }
  return (
    <Row cols={1} gapY="lg">
      <Heading2 textTag="p">
        {headingMessage}
      </Heading2>
      <Text className="h-10">
        You can create an account now to checkout faster
        in the future and keep track of your order history.
      </Text>
      {submitFailed && (
        <Link className="no-underline" href="/forgot-password">
          <Col>
            <Text className="text-error inline cursor-pointer">
              This email is already taken. Please try to
            </Text>
            <Text
              className="ml-2 cursor-pointer text-error inline-block underline"
              data-testid="forgot_password_link"
            >
              Recover your password
            </Text>
          </Col>
        </Link>
      )}
      <form
        id="sign_up_form"
        name="User Sign Up form"
        autoComplete="on"
        action=""
        noValidate
        onSubmit={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onFormSubmit().catch(error)
        }}
      >
        <Row cols={1}>
          <AlphanumericInput
            asterisk
            required
            id="sign_up_firstName"
            name="firstName"
            placeholder="First Name"
            label="First Name"
            autoComplete="given-name"
            value={firstName.value}
            inputRef={firstName.ref}
            errorMessage={(firstName.showIssues && firstName.issues) || ''}
            onChange={withFormChange(firstName)}
            onBlur={trimOnBlur(firstName)}
          />
          <AlphanumericInput
            asterisk
            required
            id="sign_up_lastName"
            name="lastName"
            placeholder="Last Name"
            label="Last Name"
            autoComplete="family-name"
            value={lastName.value}
            inputRef={lastName.ref}
            errorMessage={(lastName.showIssues && lastName.issues) || ''}
            onChange={withFormChange(lastName)}
            onBlur={trimOnBlur(lastName)}
          />
          <AlphanumericInput
            asterisk
            required
            id="sign_up_email"
            name="email"
            placeholder="Email Address"
            label="Email Address"
            autoComplete="email"
            value={email.value}
            inputRef={email.ref}
            errorMessage={(email.showIssues && email.issues) || ''}
            onChange={withFormChange(email)}
            onBlur={trimOnBlur(email)}
          />
          <AlphanumericInput
            asterisk
            required
            id="sign_up_password"
            name="password"
            autoComplete="new-password"
            placeholder="Create a Password"
            label="Create a Password"
            value={password1.value}
            inputRef={password1.ref}
            errorMessage={(password1.showIssues && password1.issues) || ''}
            onChange={withFormChange(password1)}
            onBlur={trimOnBlur(password1)}
            type="password"
          />
          <AlphanumericInput
            asterisk
            required
            id="sign_up_re_enter_password"
            name="reEnterPassword"
            autoComplete="new-password"
            placeholder="Re-enter Password"
            label="Re-enter Password"
            value={password2.value}
            inputRef={password2.ref}
            errorMessage={(password2.showIssues && password2.issues) || ''}
            onChange={withFormChange(password2)}
            onBlur={trimOnBlur(password2)}
            type="password"
          />
          <AlphanumericInput
            asterisk
            required
            id="sign_up_phone"
            name="phone"
            autoComplete="tel"
            placeholder="Phone"
            label="Phone"
            value={phone.value}
            inputRef={phone.ref}
            errorMessage={(phone.showIssues && phone.issues) || ''}
            onChange={withFormChange(phone)}
            onBlur={trimOnBlur(phone)}
            type="tel"
          />
          <Checkbox
            id="sign_up_newsLetterCheckbox"
            name="newsletterCheckbox"
            checked={!!emailSignUp.value}
            onChange={() => emailSignUp.setValue(!emailSignUp.value)}
            label="Stay in the know | Get 20% off your first order when you join our list. Valid on reg-price items only."
          />
          <PrimaryButton htmlType="submit" type="primary">
            {isSubmitting ? '...Creating your Account' : 'Create Account'}
          </PrimaryButton>
        </Row>
      </form>
    </Row>
  )
}

export default SignUpForm
